
// @ is an alias to /src
import CKEditor from '@/components/CKEditor.vue'

import ExcursionAdminModal from '@/components/excursions/ExcursionAdminModal.vue'
import ExcursionSubscriptions from '@/components/subscriptions/ExcursionSubscriptions.vue'
import ExcursionButtons from '@/components/excursions/ExcursionButtons.vue'

import { UPDATE_EXCURSION_REQUEST, EXCURSION_REQUEST } from '@/store/actions/excursion.ts'
import { SUBSCRIBE_REQUEST, UNSUBSCRIBE_REQUEST } from '@/store/actions/subscription.ts'

import { mapGetters, mapState } from 'vuex'

import { urldecode } from '@/utils/index'

//import ReisBanner from '@/components/excursions/ReisBanner.vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
    name: 'Excursie',
    data: function() {
        return {
            mounted: false,
            refresh_subscriptions_id: null,
            scope: null,
            excursion_name: null,
            tab: 'algemeen',
            subscriptions: 0,
            all_subscriptions: 0,
            excursion: {},
        }
    },
    components: {
        CKEditor,
        ExcursionAdminModal,
        //ReisBanner,
        ProgressBar,
        ExcursionSubscriptions,
        ExcursionButtons,
    },
    methods: {
        showExcursionSubscriptionsModal() {
            this.$bvModal.show('excursion-subscriptions-modal')
        },
        showExcursionAdminModal() {
            this.$bvModal.show('excursion-admin-modal')
        },
        /*
        setBanner(value) {
            this.excursion.id = this.excursion._id
            this.excursion['banner'] = value
            this.$store.dispatch(UPDATE_EXCURSION_REQUEST, this.excursion).then()
        },
        */
        nope() {
            alert('not implemented yet')
        },
        goToTab: function(tab) {
            this.$router.push('/activiteiten/' + this.scope + '/' + this.excursion_name + '/' + tab)
        },
        activeTab: function(tab) {
            return (tab === this.tab)
        },
        async update(tab, value) {
            this.excursion.id = this.excursion._id
            this.excursion[tab] = value
            await this.$store.dispatch(UPDATE_EXCURSION_REQUEST, this.excursion).then()
            this.$forceUpdate()
        },
        subscribe: function() {
            this.$store.dispatch(SUBSCRIBE_REQUEST, { 'excursion_id': this.excursion._id })
        },
        unSubscribe() {
            this.$store.dispatch(UNSUBSCRIBE_REQUEST, this.excursion.subscribed)
        },
    },
    created: async function() {
        this.scope = this.$route.params.scope
        this.excursion_name = urldecode(this.$route.params.excursion)

        try {
            this.excursion = this.excursions.filter(ex => ex.name == this.excursion_name)[0]
            if (!this.excursion) {
                this.excursion = {}  // First reset the variable before throwing an error
                throw new Error('Excursion not found in store')
            }
        } catch (err) {
            // Only load the needed excursion and set it right!
            await this.$store.dispatch(EXCURSION_REQUEST, {name: this.excursion_name}).then(() =>{
                this.excursion = this.$store.state.excursion.excursion
            })
                .catch(() => {
                    alert('Activiteit niet gevonden.')
                    this.$router.push('/activiteiten/' + this.scope)
                })
        }
        ['description', 'program', 'transport', 'residence', 'included', 'other'].map((tab) => {
            if (!(tab in this.excursion)) this.excursion[tab] = ''
        })
    },
    mounted: function () {
        this.mounted = true
        let tab = this.$route.params.tab
        if (tab != null) {
            this.tab = this.$route.params.tab
        } else {
            this.tab = 'algemeen'
        }
    },
    computed: {
        isKeuzedeel: function() {
            return this.excursion.type === 'keuzedeel'
        },
        ...mapState({
            excursions: (state: any) => state.excursion.excursions,
            profile: (state: any) => state.user.profile
        }),
        ...mapGetters([
            'isAdmin',
            'isTeacher',
            'isStudent',
            'getMyHours'
        ]),
        getTab: function() {
            return this.tab
        },
        getPrice: function() {
            let costs = this.excursion.costs_students
            if (typeof(costs) === 'number' && costs > 0) {
                return '€' + this.excursion.costs_students
            }
            return 'Gratis'
        },
        getActiveClass() {
            if (!this.mounted) return
            if (!this.excursion.active) return 'inactive-excursion'
            return ''
        }
        /*
        getBannerContent: function() {
            if (!(this.excursion)) return ''
            if (!('banner' in this.excursion)) return ''
            return this.excursion['banner']
        },
        */
    },
    watch: {
        $route: function(to) {
            this.tab = to.params.tab
        },
        'excursion.subscriptions': function(newValue) {
            if (!(typeof(newValue) === 'object')) return
            this.subscriptions = newValue.filter(sub => sub.preference == 1).length
            this.all_subscriptions = newValue.length
        }
    },
    destroyed() {
        this.$store.state.excursion.excursion = {}
        clearInterval(this.refresh_subscriptions_id)
    }
}
